<template>
  <div>
    <div class="url-share-box">
      <div class="url-box">
        <div class="boxCol-item"><em class="blue-border"></em> 扫码分享</div>
        <div class="qr-code-box">
          <div class="url-type-box">
            <span>二维码</span>
          </div>
          <div class="qr-code-show">
            <vue-qr :text="shareUrl"></vue-qr>
          </div>
          <div class="qr-code-operate">
            <p>
              <a-icon
                type="wechat"
                style="font-size: 14px; margin-right: 4px"
              />微信扫一扫分享
            </p>
          </div>
        </div>
      </div>
      <div class="share-box">
        <div class="boxCol-item"><em class="blue-border"></em> 链接分享</div>
        <div style="margin-bottom: 20px">
          <a-input ref="userNameInput" v-model="shareUrl">
            <a-icon slot="prefix" type="share-alt" />

            <a-button
              slot="suffix"
              type="primary"
              class="testCopy"
              @click="copy"
            >
              复制
            </a-button>
          </a-input>
        </div>
        <div class="boxCol-item"><em class="blue-border"></em> 分享传播</div>
        <div>
          <p style="color: #1593ff">
            <a-icon
              type="desktop"
              style="margin-right: 8px; margin-bottom: 6px"
            />去广告
          </p>
          <div>
            功能开启后将去除作品底标、作品尾页与第三方广告，您的【去广告】<span class="key">{{lastTime(record.hideadtime!=null?record.hideadtime:new Date() )}}</span>，请
            <span class="adv" @click="record.removeAdv(record.workno)">立即购买</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import Clipboard from "clipboard";
// import workMixin from "../components/workOperation.js"
export default {
  // mixins: [workMixin], 
  components: {
    VueQr,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  data() {
    return {
      shareUrl: "",
    };
  },
  mounted() {
    this.shareUrl =process.env.VUE_APP_DESIGNER+
      "xedesign/h5/showworks?workno=" +
      this.record.workno;
  },
  methods: {
    copy() {
      let txt = this.shareUrl;
      let clipboard = new Clipboard(".testCopy", {
        text: function () {
          return txt;
        },
      });
      clipboard.on("success", (e) => {
        console.log(e);

        this.$message.info("复制成功");
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        this.$message.info("该浏览器不支持复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    lastTime(Time) {
      var date1 = new Date(); //开始时间

      var date2 = new Date(Time); //结束时间
      var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      console.log(date2);

      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      if (seconds > 0) {
        return (
          " 剩余 " +
          days +
          "天 " +
          hours +
          "小时 " +
          minutes +
          " 分钟" +
          seconds +
          " 秒" 
        );
      } else {
        return "权益不足";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.url-share-box {
  display: flex;
  justify-content: space-around;
  .url-box {
    width: 152px;
  }
  .share-box {
    width: 290px;
    /deep/.ant-input-suffix {
      right: 0px !important;
    }
  }
}
.boxCol-item {
  width: 120px;
  height: 20px;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  .blue-border {
    display: inline-block;
    width: 4px;
    height: 14px;
    vertical-align: -2px;
    background: #1593ff;
    margin-right: 8px;
    border-radius: 2px;
  }
}
.qr-code-box {
  width: 152px;
  height: 212px;
  border-radius: 3px;
  border: 1px solid #e6ebed;
  .url-type-box {
    height: 30px;
    padding: 4px 6px;
    border-bottom: 1px solid #e6ebed;
    span {
      &::after {
        content: "";
        display: block;
        background: #1593ff;
        width: 46px;
        height: 2px;
        margin-top: 3px;
      }
    }
  }
  img {
    width: 100%;
  }
}
.qr-code-operate {
  padding-top: 6px;
  text-align: center;
  p {
    text-align: center;
    font-size: 12px;
    color: #999;
    line-height: 18px;
  }
}
.key{
  color: #1890ff;
}
.adv{
  color: #1890ff;
  cursor: pointer;
  &:hover{
    text-decoration:underline
  }
}
</style>
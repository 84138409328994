<template>
    <div class="integral">
        <div class="integral-top">
			<div class="padding flex justify-around align-center">
				<div class="text-white text-xxl" >
					<div class="text-xs margin-bottom-xs">当前积分</div>
					<div>{{xeupoints}}</div>
				</div>

				<div class="text-white text-xxl">
					<div class="text-xs margin-bottom-xs">我的积分总额</div>
					<div>{{xeutotalpoints}}</div>
				</div>
				<div class="text-white text-xxl">
					<div class="yellow">{{userGrade}}</div>
				</div>
			</div>
		</div>
        <div class="integral-concent">
			<div class="padding">
				<div class="bg-white padding radius">
					<div class="flex  justify-between box1">
						<div><p class="text-black text-bold text-xl ">积分指南</p></div>
						<div><p  class="text-grey">*新用户赠送5000积分</p></div>
					</div>
					<div class=" flex  justify-between box2">
						<div>等级 </div>
						<div>对应积分</div>
					</div>
					<div class=" flex  justify-between box3" v-for="(item,index) in levelrule" :key="index">
						<div><p class="yellow">{{item.xeulvname}}</p> </div>
						<div><p>{{item.lvminpointsPre}}-{{item.lvminpoints}}积分</p></div>
					</div>
					
					
					
					
				</div>
				<div class="box4 padding">
					<p class="text-orange">升级机制：系统每天凌晨定时判断积分，如果达到一定条件用户就可以升级。</p>
				</div>
				
			</div>
		</div>
    </div>
</template>

<script>

	import { mapGetters } from 'vuex'
	import {
		getUserlevelrule
	} from '@/api/manage'; // 导入我们的api接口

	export default {

		data() {
			return {
				levelrule:[],
			}
		},
		computed: {
			...mapGetters(["userGrade","xeupoints","xeutotalpoints"])
			
		},
		mounted() {
			this.userlevelrule()
		},
		methods: {
			userlevelrule(){
				getUserlevelrule().then(data => {
					
					let levelrules=[]
                    let Data=data.data
					for(var i=0;i<Data.length;i++){
						var obj={};
						if(i==0){
							obj.lvminpointsPre=0
							
						}else{
							obj.lvminpointsPre=Data[i-1].lvminpoints
						}
						obj.lvminpoints=Data[i].lvminpoints
						obj.xeulvname=Data[i].xeulvname
						levelrules.push(obj)
						
					}
					this.levelrule=levelrules
					console.log(levelrules)
				}).catch(() => {
					
				})
			}
			
		}
	}
</script>

<style scoped>
.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}
.justify-around {
  
    justify-content: space-between;
}
.align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.text-xs{
    font-size: 14px;
}
.text-xxl{
    font-size: 20px;
    text-align: center;
}
.yellow{
    color: #fbbd08;
}
.text-bold{
    font-size: 16px;
    font-weight: bold;

}
.integral{
    padding: 20px 10px;
}
.integral-top{
    margin-bottom: 30px;
}
.box1{
		height: 30px;
		line-height: 30px;
	}
	.box2{
		background: #f1f2f6;
		color: #a5aec5;
		height: 28px;
		line-height: 28px;
		padding: 0 12px;
		border-radius: 4px;
		margin: 10px 0;
        font-size: 16px;
        /* font-weight: bold; */
	}
	.box3{
		padding: 6px 6px;
        line-height: 20px;
		border-bottom: 1px solid #eeeeee;
	
	}
	.box4{
		background: #fffaf4;
		font-size: 14px;
		line-height: 24px;
        margin-top: 20px;
        padding: 2px;
        text-align: center;
		
	}
</style>
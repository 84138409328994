<template>
    <div>
        <work-bench></work-bench>
        <me-tabs v-model="tabIndex" :tabs="tabs" @change="tabChange"></me-tabs>
        <div class="workWrap">
            
             <a-list  item-layout="horizontal" :loading='loading' :data-source="couponlist" :pagination="{ ...paginationProps, current: currentPage }">
                <a-list-item slot="renderItem" slot-scope="item">
                    <work-item :worksItem='item' :conponType='conponType' @tabChange='tabChange'></work-item>
                </a-list-item>
            </a-list>
        </div>
        <!-- <a-pagination v-model="current" :total="50" show-less-items /> -->

    </div>
</template>
<script>
import workItem from "./components/workItem.vue";
import workBench from "./components/workBench.vue";
import meTabs from "@/components/meTabs";
import { getXeuworks } from "@/api/manage";
export default {
  components: {
    workItem,
    workBench,
    meTabs,
  },
  data() {
    return {
        tabs: [{name:'全部',istype:0}, {name:'海报',istype:1}, {name:'H5',istype:2}, {name:'表单',istype:3},],
        tabIndex: 0,
        couponlist:[],
        currentPage: 1, // 当前页
        total:0,
        pageSize:14,
        loading: false,
        conponType:1,
    };
  },
  mounted(){
      this.tabChange()
  },
  computed: {
    paginationProps () {
      const _this = this
      return {
        // showQuickJumper: true,
        pageSize: _this.pageSize,
        total: _this.total,
        onChange (page, pageSize) {
            console.log(page,pageSize)
            _this.loading=true
          _this.currentPage = page
          _this.tabChange()
        }
      }
    },

  },
  methods: {
      tabChange(){
        
          this.loading=true
           getXeuworks({
         pageSize: this.pageSize,
        pageNo:this.currentPage,
        type:this.tabIndex,

   
      }).then(({ data }) => {
          this.loading=false
          this.total=parseInt(data.total)
          this.couponlist=data.data
        console.log(data)
      });
      }
     
  },
};
</script>
<style lang="less">
.business-nav-box{
    width: 100%;
    
    ul{
        display: flex;
        li{
            margin-right: 20px;
            position: relative;
            display: block;
            height: 28px;
            line-height: 28px;
            color: #333;
            text-align: center;
            background-color: #f1f5f9;
            border-radius: 18px;
            padding: 0 26px;
            font-size: 14px;
            cursor: pointer;
            transition:all .4s;
            &.active,&:hover{
                color: #fff;
                background-color: #1261ff;
                font-weight: 600;
            }
        }
    }
}
</style>
<style lang="less" scoped>

.workWrap{
    // display: flex;
    // flex-wrap: wrap;
    // border-top:1px solid #e6e6e9;
}
/deep/.ant-list-items{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;
}
.ant-list-item{
    width: 200px;
    margin: 14px;
    border: none !important;
}
</style>
<template>
  <div class="workItem">
    <div class="work-content">

      <div class="work-main">
        <div class="publishstatus" v-if='worksItem.worktype!=1'  @click="publishReason(worksItem)"
          :class="worksItem.publishstatus==1?'bg-blue':(worksItem.publishstatus==2?'bg-red':'bg-orange')">
          {{publishstatusTxt(worksItem.publishstatus)}}
        </div>
        <div class="thumbnail">
          <img
            :src="ossUrl + worksItem.workthumbnail"
          />
        </div>
        <div class="worktitleWrap">
          <div class="title-box">
            <div class="workCategory">{{ worktype(worksItem.worktype) }}</div>
            <span class="title ellipsis">{{ worksItem.workname }}</span>
          </div>
          <div class="workTime">
            <p>创建时间：{{ worksItem.CREATED_TIME }}</p>
            <p>更新时间：{{ worksItem.UPDATED_TIME }}</p>
          </div>
        </div>
        <div class="work-operate">
          <div class="edit"  @click="editWork(worksItem.workno,worksItem.worktype)"><i class="iconfont iconbianji"></i>编辑</div>
          <div class="line"></div>
          <div class="delete" @click="deleteWorks(worksItem.workno)"><i class="iconfont iconshanchu1"></i>删除</div>
          <div class="line" v-show="worksItem.worktype != 1"></div>
          <a-popover title="更多" placement="bottomRight">
            <template slot="content">
              <div class="more">
                <li @click="removeAdv(worksItem.workno)"><i class="iconfont iconguanggao"></i>去除广告</li>
                <li @click="dataWorks(worksItem.workno)"><i class="iconfont iconshuju"></i>数据统计 (<span class="key">{{worksItem.sc}}</span>数据)</li>
                <li @click="shareWorks(worksItem.publishstatus,worksItem.isonline,worksItem.workno,worksItem.hideadtime)"><i class="iconfont iconfenxiang1"></i>分享作品</li>
                <li v-if="worksItem.isonline==0" @click="onlineWork(worksItem.workno,worksItem.publishstatus)"><i class="iconfont iconshangxian" ></i>作品上线</li>
                <li v-else @click="offlineWork(worksItem.workno)"><i class="iconfont iconxiaxian" ></i>作品下线</li>
              </div>
            </template>
            <div class="morebtn" v-show="worksItem.worktype != 1">
              <i class="iconfont iconmore"></i>更多
            </div>
          </a-popover>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import workMixin from "./workOperation.js"
import { mapGetters } from "vuex";
export default {
  mixins: [workMixin],
  props: {
    worksItem: {
      type: Object,
    },
    conponType: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed:{
      ...mapGetters(["ossUrl"]),
  },
  methods: {
    worktype(type) {
      switch (type) {
        case 1:
          return "海报";
        case 2:
          return "H5";
        case 3:
          return "表单";
        default:
          return "其它";
      }
    },
    publishReason(worksItem){

				if(worksItem.publishstatus==2){
					// this.ReasonTxt=productData.auditremark
					// this.modalName='Reason'

				}else{
					return
				}
			},
      publishstatusTxt(publishstatus){
				if(publishstatus==0){
					return  "待审核"
				}else if(publishstatus==1){
					return "审核中"
				}else if(publishstatus==2){
					return "审核失败"
				}else if(publishstatus==3){
					return "审核通过"
				}else{
          return ''
        }

			},
  },
  mounted() {

  },
};
</script>
<style lang="less" scoped>

.more {
  // display: none;
  // position: absolute !important;
  // width: 182px;
  // left: -124px;
  // padding: 12px;
  // background: #fff;
  // border: 1px #d5dcee solid;
  // color: #000;
  // box-shadow: 2px 5px 13px 0px #dcdcdc;
  // z-index: 99;
  li {
    // margin: 6px 0;
    padding: 6px;
    cursor: pointer;
    .iconfont {
      font-size: 16px;
      margin-right: 4px;
    }
    &:hover {
      color: #000;
      background: #e8ebf4;
    }
  }
}
</style>
<style lang="less" scoped>
.workItem {
  //    width: calc((100%-2*9*1%) / 8);
  //    margin: 20px .8%;
  //    float: left;
  width: 100%;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 2px 5px 13px 0px #dcdcdc;
    transform: translate3d(0, -4px, 0);
    -webkit-transform: translate3d(0, -4px, 0);
    -moz-transform: translate3d(0, -4px, 0);
  }
  .work-content {
    position: relative;
    // padding-top: calc(100% + 160px);
    height: 488px;
  }
  .work-main {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .thumbnail {
    position: relative;
    width: 100%;
    //    height: calc(100% - 160px);
    height: 354px;
    background-image: linear-gradient(
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 24%,
      rgb(255, 255, 255) 76%,
      rgba(230, 235, 237, 0.7) 100%
    );
    overflow: hidden;
    border-top: 1px solid #e6ebed;
    border-left: 1px solid #e6ebed;
    border-right: 1px solid #e6ebed;
    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 3px 3px 0 0;
      box-shadow: 0 4px 12px 0 rgb(0 0 0 / 8%);
    }
  }
}
.key{
  padding: 0 5px;
  text-decoration: underline;
  color: #1261ff;
}
.worktitleWrap {
  // width: 100%;
  height: 92px;
  padding: 0 12px;
  border-left: 1px solid #e6ebed;
  border-right: 1px solid #e6ebed;
  .title-box {
    width: 100%;
    padding: 14px 0;
    // height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .workCategory {
      padding: 2px 6px;
      margin-right: 4px;
      background: #ffa408;
      color: #fff;
      width: 54px;
      font-size: 12px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .workTime {
    font-size: 12px;
    p {
      line-height: 0.6;
      color: #a5aec5;
    }
  }
}
.work-operate {
  border: 1px solid #e6ebed;
  padding: 6px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .iconfont {
    // display: block;
    // text-align: center;
    font-size: 14px;
    color: #000;
    margin-right: 4px;
  }
  .line {
    width: 1px;
    height: 16px;
    background: #686869;
  }
  div {
    position: relative;
    // &:hover {
    //   color: #1261ff;
    // }
    // &:after{
    //     content:'';
    //     width: 1px;
    //     height: 18px;
    //     position: absolute;
    //     top: 50%;
    //     margin-top: -18/2px;
    //     left: 160%;
    //     // margin-left: ;
    //     background: #686869;

    // }
    // &:nth-last-child(1){
    //     &:after{
    //         background: #fff;
    //     }
    // }
  }
}
.edit,
.delete,
.morebtn {
  padding: 4px 8px;
  border-radius: 4px;
  &:hover {
    background: #eaedf6;
    color: #000;
  }
}
.publishstatus{
  position: absolute;
  top:6px;
  left: 6px;
  z-index: 99;
  color: #fff;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 12px;
  background: #f6bb6c;
  &.bg-blue{
    background: #105ce9;

  }
  &.bg-red{
    background: #ef1f37;
  }
}
</style>
<template>
  <div class="removeAdv">
    <div class="top">
      <h2>去除广告</h2>
      <div class="top-concent">
        <div>
          <div class="gg1">使用积分，获取去除广告权益。</div>
        </div>
        <div>
          <div class="gg2">
            当前积分<span class="num">{{ xeupoints }}</span
            ><span>/金币</span>
          </div>
          <div class="gg3">此作品剩余: 0次/{{intNumRule}}积分去除广告展示</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-concent">
        <div>去除广告</div>
        <div class="advbox">
          <div>
            <a-input
              ref="userNameInput"
              :suffix="'天/'+intNumRule+'积分'"
              v-model="dayNum"
              placeholder="请输入天数"
            >
              <a-icon slot="prefix" type="desktop" />
            </a-input>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <!-- <div class="btn">
      <a-button type="primary" value="large" @click="intSubmit"> 确定 </a-button>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from '@/store'
import { getPointsrule,PostRemovedads } from "@/api/works";

export default {
  props: {
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dayNum: "",
      intNumRule:0,
      workno:"",
    };
  },
  computed: {
    ...mapGetters(["xeupoints"]),
  },
  methods: {
    showPointsrule() {
      getPointsrule({pruleid: 6}).then((res) => {
        console.log(res);
        this.intNumRule=res.data.pchangecount
      });
    },
    intSubmit(){
      console.log(this.$parent.handleOk)
    },
    onOk () {
         
      console.log('监听了 modal ok 事件')
      return new Promise(resolve => {
        PostRemovedads({
					// xeucode: 10086688,
					day: this.dayNum,
					workno:this.workno
				}).then(res => {
					console.log(res)
          
          if(res.code==200){
             this.$message.info('去除广告成功');
             store.dispatch('GetInfo')
            resolve(true)
          }else{
            this.$message.info(res.msg);
          }
					

				}, err => {

					console.log(err)
				});
        
      })
    },
    onCancel () {
      console.log('监听了 modal cancel 事件')
      return new Promise(resolve => {
        resolve(true)
      })
    },
    // onOk(){
        
      //   PostRemovedads({
			// 		// xeucode: 10086688,
			// 		day: this.dayNum,
			// 		workno:this.workno
			// 	}).then(res => {
			// 		console.log(res)
      //     return new Promise(resolve => {
      //   resolve(true)
      // })
					

			// 	}, err => {

			// 		console.log(err)
			// 	});
    // }
  },
  mounted() {
    this.showPointsrule()
    this.workno=this.record.workno
    console.log(this.record.workno)
  },
};
</script>
<style lang="less" scoped>

/deep/.guishun {
  .ant-modal-content {
    background-color: #000;
  }
}
.removeAdv {
  width: 400px;
  border-radius: 12px;
  .top {
    height: 190px;
    width: 100%;
    background: url(../../../assets/img/merberbg.png);
    position: relative;
    padding: 16px 10px 0 10px;
    &:after {
      content: "";
      background: url(../../../assets/img/hg.png);
      background-size: 100% 100%;
      width: 110px;
      height: 110px;
      position: absolute;
      bottom: -2px;
      right: 8px;
    }
    h2 {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 12px;
    }
    // background-size: 100% auto100%;
    .top-concent {
      width: 380px;
      min-height: 145px;
      padding: 20px 24px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -380/2px;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      background-image: linear-gradient(to right, #feecd3, #e3c192);
    }
  }
  .bottom {
    padding: 27px 20px 32px 20px;
    background: #fff;
  }
  .btn {
    border-top: 1px solid #e6e6e9;
    justify-content: flex-end;
    display: flex;
    .ant-btn {
      border-radius: 0px;
      height: 48px;
      padding: 0 30px;
      font-size: 16px;
    }
  }
}
.advbox {
  margin-top: 20px;
}
.gg1 {
  color: #333333;
  font-size: 14px;
}
.gg2 {
  color: #9a6e42;
  font-size: 16px;
  margin-top: 4px;
  // font-weight: bold;
  span {
    color: #fc592d;
  }
  .num {
    color: #fc592d;
    font-size: 36px;
    font-weight: bold;
    padding: 0 8px 0 12px;
  }
}
.gg3 {
  color: #9a6e42;
  font-size: 14px;
}
</style>
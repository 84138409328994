<template>
    <div>
        {{msg}}
    </div>
</template>

<script>


export default {
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    };
  },

  methods: {

  },
  mounted() {

  },
};
</script>
<template>
    <div id="workBench">
        <h2>工作台</h2>
        <div class="workBench-main">
            <div class="personal-information">
                <div class="information-box">
                    <div class="avatar">
                        <a-avatar
                            size="large"
                            :src="avatar"
                        />
                    </div>
                    <div>
                        <div class="personal">
                            <div class="xeName">{{nickname}}</div>
                            <div class="xegrade">{{userGrade}}</div>
                            <div class="xemethod" @click="showIntegralrulemodel">(如何提升等级<i class="iconwenhao iconfont"></i>)</div>
                        </div>
                        <div class="xeNum">{{member.xeuCode}}</div>
                    </div>
                </div>
            </div>
            <div class="personal-integral">
                <div class="integral-box">
                    <div class="JumpIntegral" @click="JumpIntegral"><span>积分记录</span></div>
                    <div class="nowIntegral">
                        <div class="title">当前积分</div>
                        <div class="num">{{xeupoints}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import integralRule from '../Dialog/integralRule'
export default {

  data() {
    return {
   
    };
  },
  computed: {
    ...mapGetters(['nickname', 'avatar','xeupoints','userGrade','member'])
  },
  mounted() {
   
  },

  methods:{
      showIntegralrulemodel(){
          let _this=this
        _this.$dialog(
          integralRule,
            // component props
            {
              record: {},
              on: {
                ok () {
                  _this.$emit('tabChange')
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              // title: '确认',
              width: 550,
              // centered: true,
              footer:null,//确定取消不要
              maskClosable: false,
              // closable:false,
              closeIcon:<svg t="1630377572851" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2172" width="21" height="21"><path d="M523.776 440.832L148.48 65.536c-18.944-18.944-50.176-18.944-69.12 0l-2.048 2.048c-18.944 18.944-18.944 50.176 0 69.12L453.12 512 77.312 887.296c-18.944 18.944-18.944 50.176 0 69.12l2.048 2.048c18.944 19.456 50.176 19.456 69.12 0l375.296-375.296 375.296 375.296c18.944 18.944 50.176 18.944 69.12 0l2.048-2.048c18.944-18.944 18.944-50.176 0-69.12L594.944 512 970.24 136.704c18.944-18.944 18.944-50.176 0-69.12l-2.048-2.048c-18.944-18.944-50.176-18.944-69.12 0L523.776 440.832z" fill="#CCCCCC" p-id="2173"></path></svg>,
              
            }
            )
      },
      JumpIntegral(){
          this.$router.push({ path: "/manage/IntegralRecord" });
      }
  }
  
};
</script>
<style lang="less" scoped>
#workBench{
    background: #f1f5f9;
    padding: 20px;
    margin-bottom: 30px;
    .workBench-main{
        display: flex;
        justify-content: space-between;
        padding: 32px 0;
    }
}
.information-box{
    display: flex;
    align-items: center;
    .avatar{
        margin-right: 16px;
        .ant-avatar-lg{
            width: 56px;
            height: 56px;
        }
    }
    .personal{
        display: flex;
        align-items: center;
        .xegrade{
            background: #f2cb51;
            margin: 0 6px;
            font-size: 12px;
            padding: 1px 8px;
            border-radius: 8px;
            color: #9a6e42;
        }
        .xemethod{
            margin: 0 6px;
            color: #b4a6ae;
            .iconwenhao{
                cursor: pointer;
                transition:all .4s;
                &:hover{
                    color: #000;
                }
            }
        }
    }
    .xeName{
        font-size: 24px;
        margin: 0 6px;
        color: #333333;
    }
    .xeNum{
        color: #a5aec5;
        font-size: 16px;
        margin: 0 6px;
    }
}
.integral-box{
    display: flex;
    align-items: flex-end;
    .JumpIntegral{
        line-height: 1;
        font-size: 14px;
        color: #0052ff;
        padding:5px 12px;
        border: 1px solid #0052ff;
        border-radius: 10px;
        margin-right: 14px;
        cursor: pointer;
		transition:all .4s;
        &:hover{
            color: #fff;
            border: 1px solid #fff;
            background: #0052ff;
        }
    }
    .nowIntegral{
        line-height: 1;
        text-align: right;
        .title{
            color: #333333;
            font-size: 14px;
            margin-bottom: 22px;
        }
        .num{
            color: #0052ff;
            font-size: 36px;
            font-weight: bold;
            line-height: 28px;
        }
    }
}
</style>
import {
    getOnlinework,
    getOfflinework,
    getDeletework,
} from '@/api/works'; 
import defaultModal from '@/components/defaultModal'
import removeAdvertisement from '../Dialog/removeAdvertisement'
import share from '../Dialog/share'
export default{
methods: {
    //编辑
    editWork(workno,worktype){
        console.log(workno,worktype)
        let url;
        let host=process.env.VUE_APP_PCDESIGNER;
        // let host='https://wpc.xuan1tech.com/';
        switch(worktype) {
									
          case 1:
            url=host+'xedesign/hb/?workno='+workno
             break;
          case 2:
            url=host+'xedesign/h5/?workno='+workno
             break;
       case 3:
        url=host+'xedesign/form/?workno='+workno
         break;
       default:
      //  this.$util.msg("参数错误",{icon:'icon'})
      
     } 
     console.log(url)
        // window.location.href = url;
        window.open(url);   
    },
    removeAdv(workno){
      console.log(workno)
      let _this=this
        _this.$dialog(
          removeAdvertisement,
            // component props
            {
              record: {workno:workno},
              on: {
                ok () {
                  _this.$emit('tabChange')
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              // title: '确认',
              width: 400,
              // centered: true,
              // footer:"<template slot='footer'></template>",//确定取消不要
              maskClosable: false,
              // closable:false,
              closeIcon:<svg t="1630377572851" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2172" width="21" height="21"><path d="M523.776 440.832L148.48 65.536c-18.944-18.944-50.176-18.944-69.12 0l-2.048 2.048c-18.944 18.944-18.944 50.176 0 69.12L453.12 512 77.312 887.296c-18.944 18.944-18.944 50.176 0 69.12l2.048 2.048c18.944 19.456 50.176 19.456 69.12 0l375.296-375.296 375.296 375.296c18.944 18.944 50.176 18.944 69.12 0l2.048-2.048c18.944-18.944 18.944-50.176 0-69.12L594.944 512 970.24 136.704c18.944-18.944 18.944-50.176 0-69.12l-2.048-2.048c-18.944-18.944-50.176-18.944-69.12 0L523.776 440.832z" fill="#CCCCCC" p-id="2173"></path></svg>,
              bodyStyle:{padding:'0px'},
               wrapClassName:'guishun'
            }
            )
    },
    onlineWork(workno,publishstatus){
        let title="上线成功,等待管理员审核"
			if(publishstatus==2){
                _this.$message.info('请重新修改作品再审核上线');
				return;
			}else if(publishstatus==3){
				title="上线成功"
			}
        let _this=this
        _this.$dialog(
            defaultModal,
            // component props
            {
              msg:'是否上线',
              on: {
                ok () {
                    let parameter = {
                        workno:workno,
                    }
                    getOnlinework(parameter)
                  .then(res => {
                    console.log(res)
                    _this.$message.info(title);
                    _this.$emit('tabChange')
                  })
                  .catch(() => {
                    
                  })
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              title: '确认',
              width: 500,
              centered: true,
              maskClosable: false
            })
        console.log(workno,publishstatus)
        // let title="上线成功,等待管理员审核"
        // if(publishstatus==2){
        //     uni.showToast({
        //         title:"请重新修改作品再审核上线",
        //         icon:"none"
        //     });
        //     return;
        // }else if(publishstatus==3){
        //     title="上线成功"
        // }
        
    },
    offlineWork(workno){
        let _this=this
        _this.$dialog(
            defaultModal,
            // component props
            {
              msg:'是否下线',
              on: {
                ok () {
                    let parameter = {
                        workno:workno,
                    }
                    getOfflinework(parameter)
                  .then(res => {
                    console.log(res)
                    _this.$message.info(res.msg);
                    _this.$emit('tabChange')
                  })
                  .catch(() => {
                    
                  })
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              title: '确认',
              width: 500,
              centered: true,
              maskClosable: false
            })
       
    },
    // viewData(workno){
    //     this.$Router.push({ path: '/pages/pageUser/works/workdata', query: { wkid: workno}})
    // },

    deleteWorks(workno){
        let _this=this
        _this.$dialog(
          defaultModal,
            // component props
            {
              msg: '是否确定删除',
              on: {
                ok () {
                    let parameter = {
                        // xeuid: 10086688,
                        workno:workno,
                    }
                    getDeletework(parameter).then(data => {
                      
                        _this.$message.info(data.msg);
                        _this.$emit('tabChange')
                    
                    }).catch(() => {
                        
                    })
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              // title: '确认',
              width: 500,
              // centered: true,
              maskClosable: false
            })
        // this.hideModal()
        
    },
    shareWorks(publishstatus,isonline,workno,hideadtime){
      let _this=this
      if(publishstatus==1){
        _this.$message.info("作品审核中，暂不能分享");
        return
      }else if(publishstatus==2){
        _this.$message.info('作品审核失败，请修改重新提交');
        return
      }else if(publishstatus==0){
        _this.$message.info('您的作品还没发布，请先上线后再分享');
        return
      }else if(isonline==0){
        _this.$message.info('您的作品还没发布，请先上线后再分享');
        return
      }else if(publishstatus==3 && isonline==1){
        _this.$dialog(
          share,
            // component props
            {
              record: {workno:workno,hideadtime:hideadtime,removeAdv:this.removeAdv},
              on: {
                ok () {
                
                },
                cancel () {
                  console.log('cancel 回调')
                },
                close () {
                  console.log('modal close 回调')
                }
              }
            },
            // modal props
            {
              // title: '确认',
              width: 600,
              // centered: true,
              footer:"",//确定取消不要
              maskClosable: false
            })
        return
      }
        
    },
    dataWorks(workno){
      this.$router.push({
        path: '/manage/worksdata/'+workno,
      })
    }
}
}